<template>
  <div style='height: 100%; padding: 16px; position: relative'>
    <div style='position: absolute; right: 16px; top: 10px; z-index: 999'>
      <el-button type='primary' plain size='small' @click='onBack'>
        <el-icon>
          <ArrowLeft />
        </el-icon>
        返回
      </el-button>
    </div>
    <el-tabs v-model='activeKey' type='card' @tab-click='handleClick'>
      <el-tab-pane name='1' label='已拆分列表'>
        <div>
          <div
            style='
              display: flex;
              justify-content: space-between;
              align-items: center;
            '
          >
            <div>
              <el-button type='primary' plain @click='add'>新建拆分</el-button>
              <el-button
                type='primary'
                plain
                @click='batchAction(SPLIT_ACTION_EDIT)'
              >批量变更
              </el-button>
              <el-button
                type='primary'
                plain
                @click='batchAction(SPLIT_ACTION_DEL)'
              >批量注销
              </el-button>
            </div>
            <div
              v-if='houseInfo && houseInfo.houseId'
              style='padding: 6px 20px; color: #999'
            >
              <p>产权证号：{{ houseInfo.warrantNo }}</p>
              <p style='margin-top: 6px'>
                建筑面积：<span
                style='
                    color: #29cf84;
                    font-size: 18px;
                    font-weight: bold;
                    padding-right: 4px;
                  '
              >{{ houseInfo.buildingArea }}</span
              >㎡
              </p>
            </div>
          </div>
        </div>
        <el-table
          v-loading='loading'
          ref='multipleTableRef'
          :data='originRoomList'
          border
          style='width: 100%'
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
          @selection-change='handleSelectionChange'
        >
          <el-table-column type='selection' width='55' />
          <el-table-column prop='roomNum' label='房间编号' />
          <el-table-column prop='roomName' label='房间号' />
          <el-table-column prop='roomSpace' label='面积(㎡)' />
          <el-table-column prop='roomType' label='类型' />
          <el-table-column prop='occupancyType' label='已使用'>
            <template #default='{ row }'>
              <span v-if="row.occupancyType === '0'">未使用</span>
              <span v-if="row.occupancyType === '1'">全部使用</span>
              <span v-if="row.occupancyType === '2'">部分使用</span>
            </template>
          </el-table-column>
          <el-table-column prop='subleaseType' label='转租情况'>
            <template #default='{ row }'>
              <span v-if="row.subleaseType === '0'">未转租</span>
              <span v-if="row.subleaseType === '1'">已转租</span>
              <span v-if="row.subleaseType === '2'">部分转租</span>
            </template>
          </el-table-column>
          <el-table-column prop='status' label='状态'>
            <template #default='{ row }'>
              <span v-if='row.status === 0'>正常</span>
              <el-tag type='danger' size='small' v-if='row.status === 1'>审核中</el-tag>
              <span v-if='row.status === 2'>新增</span>
            </template>
          </el-table-column>
          <el-table-column label='操作' width='120'>
            <template #default='scope'>
              <el-button
                type='text'
                size='small'
                class='list-btn'
                @click='edit([scope.row])'
              >变更
              </el-button>
              <el-button
                type='text'
                size='small'
                class='list-btn'
                @click='del([scope.row])'
              >注销
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout='total, prev, pager, next'
          :total='total'
          :page-size='pageSize'
          style='text-align: right; margin: 10px 0'
        />
      </el-tab-pane>
      <el-tab-pane name='2' label='拆分办理状态查询'>
        <status-table
          ref='statusTableRef'
          v-if='houseInfo && houseInfo.houseNumber'
          :house-number='houseInfo.houseNumber'
        ></status-table>
      </el-tab-pane>
    </el-tabs>

    <action-modal ref='actionModalRef' @success='reloadList'></action-modal>
  </div>
</template>

<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import ActionModal from './src/action-modal'

import {
  reactive,
  onActivated,
  ref,
  defineComponent,
  onMounted,
  provide
} from 'vue'
import { getRentBwSelectOptions } from '@/utils/split'
import { getSubleaseHouse, getHouseById } from '@/api/user-house'
// import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import {
  SPLIT_ACTION_ADD,
  SPLIT_ACTION_EDIT,
  SPLIT_ACTION_DEL
} from '@/constant'
import StatusTable from './src/status-table'
import { ArrowLeft } from '@element-plus/icons-vue'

import { ElMessage } from 'element-plus'

const route = useRoute()
const router = useRouter()

const loading = ref(false)
const actionModalRef = ref()
const multipleTableRef = ref(null)
const multipleSelection = ref([])
const originRoomList = ref([])
const houseInfo = ref()
const statusTableRef = ref()
// 注解参数
// 已拆房源列表
provide('roomList', originRoomList)
// 房屋信息
provide('houseInfo', houseInfo)

const handleSelectionChange = (val) => {
  console.log(val)
  multipleSelection.value = val
}
const activeKey = ref('1')
const total = ref(0)
const tableData = ref([])

// 当前是覅偶有待办业务
const hasBusiness = () => {
  const checks = originRoomList.value.filter(
    (item) => item.businessNo != null && item.businessNo != ''
  )
  return checks && checks.length > 0
}
const ERROR_MSG = '请等待审核完毕上一笔变更业务，再重新申请变更'

const add = () => {
  if (hasBusiness()) {
    ElMessage.error(ERROR_MSG)
    return
  }
  actionModalRef.value.open({ type: SPLIT_ACTION_ADD })
}
const edit = (rows) => {
  if (hasBusiness()) {
    ElMessage.error(ERROR_MSG)
    return
  }
  actionModalRef.value.open({ type: SPLIT_ACTION_EDIT, rows })
}
const del = (rows) => {
  if (hasBusiness()) {
    ElMessage.error(ERROR_MSG)
    return
  }
  actionModalRef.value.open({ type: SPLIT_ACTION_DEL, rows })
}
// 批量操作
const batchAction = (type) => {
  if (hasBusiness()) {
    ElMessage.error(ERROR_MSG)
    return
  }
  actionModalRef.value.open({ type, rows: multipleSelection.value })
}
const onBack = () => {
  console.log('back')
  router.back()
}

const pageSize = ref(0)

// 测试数据，后面要换成房源列表入口

const getSplitRooms = async (house) => {
  if (!originRoomList.value.length) {
    try {
      loading.value = true
      const resp = await getSubleaseHouse({
        houseId: route.query.id,
        houseNum: route.query.fwbh,
        parentRoomNum: ''
      })
      const roomData = resp.subleaseEntitys
      const subHouseNum = house.lesseeRoomNumber
      if (subHouseNum) {
        const tempList = roomData.filter((item) => item.roomNum == subHouseNum)
        getSubHouse(roomData, subHouseNum, tempList)
        originRoomList.value = tempList
      } else {
        originRoomList.value = roomData
      }
    } finally {
      loading.value = false
    }
  }

  // rentSplitList.value = getRentBwSelectOptions(
  //   originRoomList.value,
  //   house.lesseeRoomNumber,
  //   house.type
  // )
}

//  在有出租房间编号的情况下，寻找该房间编号下所有套，间数据
const getSubHouse = (list, subHouseNum, tempList) => {
  if (list.length) {
    list.map((item) => {
      if (item.parentRoomNum == subHouseNum) {
        tempList.push(item)
        getSubHouse(list, item.roomNum, tempList)
      }
    })
  }
}
const reloadList = ({ type = SPLIT_ACTION_ADD }) => {
  if (SPLIT_ACTION_ADD) {
    activeKey.value = '2'
    statusTableRef.value.reloadList()
  } else {
    getSplitRooms(houseInfo.value)
  }
}

const fetchHouseInfo = async () => {
  try {
    loading.value = true
    const { houseIndfo = {}, minioUrl = '' } = await getHouseById({
      houseId: route.query.id
    })
    houseInfo.value = houseIndfo
    await getSplitRooms(houseInfo.value)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchHouseInfo()
})
</script>

<style lang='scss' scoped>
::v-deep .el-dialog__body {
  padding: 0 20px;
}

.tip-wrapper {
  color: #ff9900;
  background: #fdf6ec;
  padding: 10px;
  border-radius: 5px;

  .title {
    font-size: 14px;
  }

  .content {
    margin-top: 5px;
    font-size: 13px;

    p {
      padding: 3px 0;
    }
  }
}
</style>
